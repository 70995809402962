import React, { useEffect, useState } from 'react';
import { TagCloud } from 'react-tagcloud';

const tagTrendStyle = {
  backgroundColor: 'black',
  maxWidth: 600,
  marginLeft: 'auto',
  marginRight: 'auto'
};

interface TagCount {
  value: string;
  count: number;
}

const TagTrend = () => {
  const tagCountMap: { [key: string]: number } = {};
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let qiita_json = await JSON.parse(await (await fetch('/get_qiita')).text());
        let zenn_json = await JSON.parse(await (await fetch('/get_zenn')).text());
        let all = qiita_json.array.concat(zenn_json.array);
        all.sort(function (a: any, b: any) {
          if (a.published > b.published) return -1
          if (a.published < b.published) return 1
          return 0
        })
        setData(all);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  data.forEach((item: any) => {
    item.tags.forEach((tag: any) => {
      if (tag !== "tech") {
        if (tagCountMap[tag]) {
          tagCountMap[tag] += 1;
        } else {
          tagCountMap[tag] = 1;
        }
      }
    });
  });

  const tagCounts: TagCount[] = Object.keys(tagCountMap)
    .map((tag) => ({
      value: tag,
      count: tagCountMap[tag],
    }))
  //.sort((a, b) => b.count - a.count);
  /*
  const options = {
    luminosity: 'light',
    hue: 'blue',
  }*/
  const options = {
    luminosity: 'light',
    hue: 'blue'
  }

  return (
    <div style={tagTrendStyle}>
      <TagCloud
        minSize={12}
        maxSize={35}
        colorOptions={options}
        tags={tagCounts}
      //onClick={(tag: any) => alert(`'${tag.value}' was selected!`)}
      />
    </div>
  );
};

export default TagTrend;
