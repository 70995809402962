import React from 'react';
import { ThemeProvider } from './context/ThemeContext';
import App from './components/App';
import './index.css';
import ReactDOM from 'react-dom/client';

// for local env
const setup = async () => {
  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import("./mocks/browser");
    worker.start();
  }
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

setup().then(() =>
  root.render(
    <React.StrictMode>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </React.StrictMode>
  ));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// import reportWebVitals from './reportWebVitals';
// reportWebVitals();