import React, { useContext } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';
import Switch from 'react-switch';
import { ThemeContext } from '../context/ThemeContext';

const DarkModeSwitch = () => {
  const themeContext = useContext(ThemeContext);
  const { darkMode, setDarkMode } = themeContext;

  return (
    <div className="dark-mode-switch">
      <FaSun />
      <Switch
        checked={darkMode}
        onChange={() => setDarkMode(!darkMode)}
        offColor="#bbb"
        onColor="#000"
        checkedIcon={false}
        uncheckedIcon={false}
        height={20}
        width={40}
      />
      <FaMoon />
    </div>
  );
};

export default DarkModeSwitch;
