import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { CardProps } from '../types';
import { FaRegHeart, FaTag } from 'react-icons/fa';

// Style
const headerStyleQiita: React.CSSProperties = {
  backgroundColor: '#55c500',
  color: 'white',
  padding: '0.5em',
  borderRadius: '5px 5px 0 0',
};
const headerStyleZenn: React.CSSProperties = {
  backgroundColor: '#3ea8ff',
  color: 'white',
  padding: '0.5em',
  borderRadius: '5px 5px 0 0',
};
const cardStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: '0.8em',
  padding: '1em',
  border: '1px solid #eaeaea',
  borderRadius: '5px',
  cursor: 'pointer',
  width: '90%',
  height: 'auto',
};
const titleStyle: React.CSSProperties = {
  fontSize: '1.5em',
  fontWeight: 'bold',
  margin: '0.5em 0',
};
const textStyle: React.CSSProperties = {
  margin: '0.1em 0',
};

const Card: React.FC<CardProps> = ({ title, url, author, published, media, likes, tags }) => {
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('clickHistory') || '[]');
    const clickedItem = storedData[url];
    if (clickedItem && clickedItem.clicked) {
      setClicked(true);
    }
  }, [url]);

  const handleClick = () => {
    window.open(url, '_blank');
    const clickHistory = JSON.parse(localStorage.getItem('clickHistory') || '{}');
    const updatedClickHistory = { ...clickHistory, [url]: true };
    localStorage.setItem('clickHistory', JSON.stringify(updatedClickHistory));
    setClicked(true);
  };

  const themeContext = useContext(ThemeContext);
  const { darkMode, hideClicked } = themeContext;
  const clickHistory = JSON.parse(localStorage.getItem('clickHistory') || '{}');
  const hasBeenClicked = clickHistory[url] ? true : false;

  if (hideClicked && hasBeenClicked) {
    // if hideClicked mode is on and the card has been clicked, don't render the card
    return null;
  }

  const headerStyle = clicked ? { ...headerStyleQiita, backgroundColor: 'grey' } : media === 'Qiita' ? headerStyleQiita : headerStyleZenn;
  const cardStyleWithDarkMode = darkMode
    ? { ...cardStyle, backgroundColor: 'black', color: 'white' }
    : cardStyle;

  return (
    <div style={cardStyleWithDarkMode} onClick={handleClick}>
      <div style={headerStyle}>{media}</div>
      <h2 style={titleStyle}>{title}</h2>
      <p style={textStyle}>Author: {author}</p>
      <p style={textStyle}>Published: {published}</p>
      <p style={textStyle}><FaRegHeart /> {likes}</p>
      <p style={textStyle}><FaTag /> {tags}</p>
    </div>
  );
};

export default Card;
