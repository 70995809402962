import React, { useEffect, useState } from 'react';
import Card from './Card';
import styled from 'styled-components';

const CardContainer = styled.div`
  display: grid;
  gap: 0px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(min-content, auto);
  grid-row-gap: 10px;
  grid-column-gap: 0px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  paddingTop: '60px',
`;

const CardList: React.FC = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let qiita_json = await JSON.parse(await (await fetch('/get_qiita')).text());
        let zenn_json = await JSON.parse(await (await fetch('/get_zenn')).text());
        let all = qiita_json.array.concat(zenn_json.array);
        all.sort(function (a: any, b: any) {
          if (a.published > b.published) return -1
          if (a.published < b.published) return 1
          return 0
        })
        setData(all);
      } catch (error) {
        console.error(error);
      }

    };

    fetchData();
  }, []);

  return (
    <CardContainer>
      {data.map((item: any, index: number) => (
        <Card
          key={index}
          title={item.title}
          url={item.url}
          author={item.author}
          published={item.published}
          media={item.media}
          likes={item.likes}
          tags={(item.tags).join(", ")}
        />
      ))}
    </CardContainer>
  );
};

export default CardList;
