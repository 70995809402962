import React, { useContext, CSSProperties } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CardList from './CardList';
import Header from './Header';
import TagTrend from './TagTrend';
import { ThemeContext } from '../context/ThemeContext';

const lightModeStyle: CSSProperties = {
  height: '100%',
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column'
};

const darkModeStyle: CSSProperties = {
  ...lightModeStyle,
  backgroundColor: 'black',
  color: 'white',
};

const App = () => {
  const themeContext = useContext(ThemeContext);
  const { darkMode } = themeContext;

  const appStyleWithDarkMode = darkMode ? darkModeStyle : lightModeStyle;

  return (
    <div style={appStyleWithDarkMode}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<CardList />} />
          <Route path="/tagtrend" element={<TagTrend />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
