import React, { useState, useEffect } from 'react';

export type ThemeContextType = {
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  hideClicked: boolean;
  setHideClicked: React.Dispatch<React.SetStateAction<boolean>>;
};

// defaultの値は適当に渡している。 これで良いのかは不明。読み取り先で正しい値をセットしているはず
// ThemeContextTypeをいじるたびにここも追加する必要がある。ややめんどいからラッパー書いたほうがましだったかも
export const ThemeContext = React.createContext<ThemeContextType>({ darkMode: false, setDarkMode: () => { }, hideClicked: false, setHideClicked: () => { } });

export const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(() => {
    // When the component mounts, we read the value of darkMode from localStorage
    const localDarkMode = window.localStorage.getItem('darkMode');
    return localDarkMode ? JSON.parse(localDarkMode) : false;
  });
  const [hideClicked, setHideClicked] = useState<boolean>(() => {
    const localHideClicked = window.localStorage.getItem('hideClicked');
    return localHideClicked ? JSON.parse(localHideClicked) : false;
  });

  useEffect(() => {
    window.localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    window.localStorage.setItem('hideClicked', JSON.stringify(hideClicked));
  }, [hideClicked]);

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode, hideClicked, setHideClicked }}>
      {children}
    </ThemeContext.Provider>
  );
};
